import { useReducer, useMemo, useCallback, memo } from 'react'
import MetaContext from './MetaContext'
import initialMetaState from './initialMetaState'
import metaReducer from 'contexts/meta/reducers/meta.reducer'
import * as action from "contexts/meta/reducers/meta.actions";
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import useContextUser from 'hooks/contexts/useContextUser';
import { ChildrenProps } from 'interfaces/general.interface';
import { TcreateSftpUserAPIBodyProps } from 'api/responseInterfaces/meta.response.interface';

function MetaProvider(props: ChildrenProps) {
	const [mState, dispatch] = useReducer(metaReducer, initialMetaState)
	const metaState = mState
	const { getAccessTokenSilently } = useAuth0()
	const { t: translate } = useTranslation()
	const { dbUser, company } = useContextUser()

	const getSftpConnections = useCallback(async () => {
		const token = await getAccessTokenSilently()
		if (company.id) {
			action.getSftpConnectionsAction(dispatch, company.id, dbUser.role, token, translate)
		}
	}, [company.id, dbUser.role, getAccessTokenSilently, translate])

	const createSftpConnection = useCallback(async (body: TcreateSftpUserAPIBodyProps) => {
		const token = await getAccessTokenSilently()
		return await action.createSftpConnectionAction(dispatch, body, token, translate) as boolean
	}, [getAccessTokenSilently, translate])

	const removeSftpConnection = useCallback(async (user_name: string) => {
		const token = await getAccessTokenSilently()
		action.removeSftpConnectionAction(dispatch, user_name, token, translate)
	}, [getAccessTokenSilently, translate])

	const memoProvider = useMemo(
		() => ({
			...metaState,
			getSftpConnections,
			createSftpConnection,
			removeSftpConnection,
		}), [
		metaState,
		getSftpConnections,
		createSftpConnection,
		removeSftpConnection,
	]
	);

	return (
		<MetaContext.Provider value={memoProvider}>
			{props.children}
		</MetaContext.Provider>
	)
}

export default memo(MetaProvider)