import * as BrandTypes from './brand.types'
import { messageError, messageSuccess } from 'views/components/UI/message';
import { notificationErr } from 'views/components/UI/notification';
import { brandError } from 'utils/errorCodes';
import * as myBrandsApi from 'api/myBrands.api';
import * as companyApi from 'api/company.api';
import { IInfoBrandState, IInitialBrandState, ITenantState } from 'interfaces/brand.interface';
import * as status from 'contexts/myBrands/constants';
import { IPointOfSaleState } from 'interfaces/pointsOfSale.interface';
import { IMyBrand } from 'interfaces/myBrands.interface';

export async function getBrandAction(dispatch: any, clientId: string, brandId: string, countryId: string, myBrands: IMyBrand[], token: string, translate: any) {
	dispatch({ type: BrandTypes.SET_IS_LOADING, payload: true })
	try {
		const [brandFetch] = await Promise.all([
			myBrandsApi.getBrandsProfileAPI(brandId, token),
		])
		if (brandFetch.response.status === 200) {
			const {
				name,
				logo,
				email,
				tenants,
				instagram,
				youtube,
				facebook,
				linkedin,
				bio,
				created_by,
				enabled,
				validated,
				contact_name,
				web,
				brand_social_media
			} = brandFetch.data
			if (brandFetch.data?.tenants.length === 0) {
				const brand: IInfoBrandState = {
					tenantId: brandId,
					tradeName: name,
					bio: bio,
					enabled: enabled,
					validated: validated,
					created_by: created_by,
					email: email,
					logo: logo,
					contact_name: contact_name,
					web: web,
					instagram: instagram,
					youtube: youtube,
					facebook: facebook,
					linkedin: linkedin,
					tenants: [],
					social_media: brand_social_media,
				}
				dispatch({
					type: BrandTypes.SELECT_TENANT,
					payload: {}
				})
				return dispatch({
					type: BrandTypes.GET_BRAND_INFO,
					payload: brand
				})
			} else {
				let tenantsAssociated: ITenantState[] = []
				for (const [index, myBrand] of myBrands.entries()) {
					const findTenant = tenants.find((item: any) => item.id === myBrand.tenant_id)
					if (findTenant) {
						const [pointsOfSaleFetch, brandContactsFetch] = await Promise.all([
							myBrandsApi.getTenantPointsOfSaleAPI(clientId, findTenant.id, token),
							companyApi.getBrandContactsAPI(findTenant.id, token)
						])
						// Get poits of sale
						let sortedPointsOfSale: IPointOfSaleState[] = []
						if (pointsOfSaleFetch.response.status === 200) {
							const sortPointsOfSale = pointsOfSaleFetch.data.sort((a: any, b: any) => a.associated === b.associated ? 0 : a.associated ? -1 : 1)
							// eslint-disable-next-line @typescript-eslint/no-unused-vars
							for (const [index, item] of sortPointsOfSale.entries()) {
								sortPointsOfSale[index].key = index + 1
							}
							sortedPointsOfSale = sortPointsOfSale
						}
						// Get contacts
						let sortContacts: any[] = []
						if (brandContactsFetch.response.status === 200) {
							const contacts = brandContactsFetch.data.company_tenant_contacts?.map((item: any) => {
								return {
									...item.contact,
									key: item.contact.id,
								}
							})
							sortContacts = contacts
						}
						findTenant.associationState = myBrands[index].state_name
						findTenant.pointsOfSale = sortedPointsOfSale
						findTenant.contacts = sortContacts
						findTenant.cif = !findTenant.cif || findTenant.cif === '0' ? '' : findTenant.cif
						findTenant.address = !findTenant.address || findTenant.address === '0' ? '' : findTenant.address
						findTenant.postal_code = !findTenant.postal_code || findTenant.postal_code === '0' ? '' : findTenant.postal_code
						findTenant.city = !findTenant.city || findTenant.city === '0' ? '' : findTenant.city
						tenantsAssociated.push(findTenant)
					}
				}

				const hydrateTenants = async () => {
					let tenantsArray: ITenantState[] = []
					for (const tenant of tenants) {
						const [pointsOfSaleFetch, brandContactsFetch] = await Promise.all([
							myBrandsApi.getTenantPointsOfSaleAPI(clientId, tenant.id, token),
							companyApi.getBrandContactsAPI(tenant.id, token)
						])
						const findTenant = tenantsAssociated.find((tenantAssociated: ITenantState) => tenantAssociated.id === tenant.id)
						// Get poits of sale
						let sortedPointsOfSale: IPointOfSaleState[] = []
						if (pointsOfSaleFetch.response.status === 200) {
							const sortPointsOfSale = pointsOfSaleFetch.data.sort((a: any, b: any) => a.associated === b.associated ? 0 : a.associated ? -1 : 1)
							// eslint-disable-next-line @typescript-eslint/no-unused-vars
							for (const [index, item] of sortPointsOfSale.entries()) {
								sortPointsOfSale[index].key = index + 1
							}
							sortedPointsOfSale = sortPointsOfSale
						}
						// Get contacts
						let sortContacts: any[] = []
						if (brandContactsFetch.response.status === 200) {
							const contacts = brandContactsFetch.data.company_tenant_contacts?.map((item: any) => {
								return {
									...item.contact,
									key: item.contact.id,
								}
							})
							sortContacts = contacts
						}
						tenantsArray.push({
							...tenant,
							cif: !tenant.cif || tenant.cif === '0' ? '' : tenant.cif,
							address: !tenant.address || tenant.address === '0' ? '' : tenant.address,
							postal_code: !tenant.postal_code || tenant.postal_code === '0' ? '' : tenant.postal_code,
							city: !tenant.city || tenant.city === '0' ? '' : tenant.city,
							associationState: findTenant ? status.connected : status.disconnected,
							pointsOfSale: sortedPointsOfSale,
							contacts: sortContacts,
						})
					}
					return tenantsArray
				}
				const hydratedTenants = await hydrateTenants()
				const brand: IInfoBrandState = {
					tenantId: brandId,
					tradeName: name,
					bio: bio,
					enabled: enabled,
					validated: validated,
					created_by: created_by,
					email: email,
					logo: logo,
					contact_name: contact_name,
					web: web,
					instagram: instagram,
					youtube: youtube,
					facebook: facebook,
					linkedin: linkedin,
					tenants: hydratedTenants,
					social_media: brand_social_media,
				}
				dispatch({
					type: BrandTypes.SELECT_TENANT,
					payload: tenantsAssociated.length > 0 ? tenantsAssociated[0] : hydratedTenants[0]
				})
				dispatch({
					type: BrandTypes.GET_BRAND_INFO,
					payload: brand
				})
				return
			}
		} else {
			messageError(translate('action_brand_get-brand_error'))
			notificationErr(brandError.get_brand_002, translate)
		}
	} catch (err) {
		console.log(err);
		messageError(translate('action_brand_get-brand_error'))
		notificationErr(brandError.get_brand_003, translate)
	} finally {
		setTimeout(() => {
			dispatch({ type: BrandTypes.SET_IS_LOADING, payload: false })
		}, 200)
	}
}

export async function shareDataWithBrandAction(dispatch: any, datashareId: string, value: boolean, state: IInitialBrandState, token: string, translate: any) {
	try {
		const [shareDataFetch] = await Promise.all([
			myBrandsApi.shareDataWithBrandAPI(datashareId, value, token),
		])
		if (shareDataFetch.response.status !== 200) {
			messageError(translate('action_brand_share-data-with-brand_error'))
			return notificationErr(brandError.share_data_with_brand_002, translate)
		}
		const findIndex = state.brand.tenantSelected.pointsOfSale.findIndex((item: any) => item.datashare_id === datashareId)
		state.brand.tenantSelected.pointsOfSale[findIndex].data_share = value
		value === true ?
			messageSuccess(translate('action_brand_share-data-with-brand_share-success')) :
			messageSuccess(translate('action_brand_share-data-with-brand_no-share-success'))
		return dispatch({
			type: BrandTypes.GET_BRAND_POINTS_OF_SALE,
			payload: state.brand.tenantSelected.pointsOfSale
		})
	} catch (error) {
		notificationErr(brandError.share_data_with_brand_001, translate)
	}
}

export async function selectTenantAction(dispatch: any, tenant: ITenantState, translate: any) {
	dispatch({
		type: BrandTypes.SELECT_TENANT,
		payload: tenant
	})
}