const italianTranslation = {
  // HEADER //////////////////
  // Nav
  'header_nav_home': 'Home',
  'header_nav_dashboard': 'Dashboard',
  'header_nav_catalog': 'Catalogo',
  'header_nav_catalog-download': 'Scarica il catalogo',
  'header_nav_multimedia': 'Multimedia',
  'header_nav_my-brands': 'Le Mie Marche',
  'header_sub-nav_view-catalog': 'Visualizza catalogo',
  'header_sub-nav_add-product': 'Aggiungi prodotto',
  'header_sub-nav_multimedia-catalog': 'Catalogo multimediale',
  'header_sub-nav_multimedia-catalog-videos': 'Video',
  'header_sub-nav_multimedia-catalog-images': 'Immagini',
  'header_sub-nav_multimedia-catalog-banners': 'Banner',
  'header_sub-nav_my-brands-list': 'Lista delle Marche',
  'header_sub-nav_add-brand': 'Aggiungi Marca',
  'header_nav_sales': 'Vendite',
  'header_nav_sales-history': 'Cronologia dei caricamenti',
  'header_nav_sales-create': 'Carica vendite',
  // Notifications
  'header_notifications_empty-notifications': 'Non ci sono notifiche',
  ////////////////////////////

  // SIGNUP PAGE /////////////
  'signup-page_title': 'Registrati per:',
  'signup-page_sub-title': 'Registrati per far crescere la tua attività',
  'signup-page_button-brand': 'Registrati come Marchio',
  'signup-page_button-multibrand': 'Registrati come Multimarchio',
  'signup-page_button-brand-wh': 'Registrarmi come marca o multimarca',
  'signup-page_return-to-login': 'Torna al Accedi',
  ////////////////////////////

  // HOME PAGE ///////////////
  'home-page_brands_my-brands_title': 'I Miei Marchi',
  'home-page_brands_item_button-see-catalog': 'Vedi Catalogo',
  'home-page_brands_my-brands_my-brands-link': 'Vai alle Mie Marche',
  'home-page_brands_all-brands_title': 'Marchi su HUB',
  'home-page_brands_all-brands_see-more': 'Vedi tutto',
  'home-page_brands_all-brands_see-less': 'Vedi meno',
  'home-page_brands_item_button-connect': 'Connetti',
  'home-page_brands_item_button-see-profile': 'Vedi profilo',
  'home-page_brands_item_button-pending': 'In attesa',
  'home-page_brands_item_button-disconnected': 'Disconnesso',
  'home-page_brands_item_empty-brands': 'Ancora nessuna associazione',
  'home-page_brands_see_more': 'Vedi di più',
  'home-page_brands_see_brand': 'Vedi Profilo',
  'home-page_brands_no_more_results': 'Nessun risultato trovato...',
  'home-page_brands_connect-modal_title': 'La richiesta è stata inviata al marchio!',
  'home-page_brands_connect-modal_text1': "Hub4Retail contatterà il marchio per associarlo.",
  'home-page_brands_connect-modal_text2': "Ti invieremo un'email con gli aggiornamenti.",
  'home-page_brands-not-associated_title': 'Marchi che vendi non associati',
  'home-page_brands-not-associated_search-input-placeholder': 'Cerca nei marchi non associati',
  'home-page_brands-not-associated_selected-brand': 'marchio selezionato',
  'home-page_brands-not-associated_selected-brands': 'marchi selezionati',
  'home-page_brands-not-associated_select-all': 'Seleziona tutto',
  'home-page_brands-not-associated_clear-select': 'Cancella selezione',
  'home-page_brands-not-associated_associate-btn': 'Associa marchi',
  'home-page_my-brands_search-input-placeholder': 'Cerca nei miei marchi',
  ////////////////////////////

  // ACCOUNT PAGE /////////////
  'profile-page_nav_profile': 'Profilo',
  'profile-page_nav_company': 'Informazioni aziendali',
  'profile-page_nav_main-contacts': 'Contatti principali',
  'profile-page_nav_my-brands': 'Le Mie Marche',
  'profile-page_nav_points-of-sale': 'Punti Vendita',
  /////////////////////////////

  // DASHBOARDS /////////////////
  'dashboard_empty-dashboard': 'Contatta Hub4Retail per abilitare questo servizio.',
  'dashboard_dashboard_error': 'Errore nel caricamento del dashboard. Se il problema persiste, contattare:',
  'dashboard_maintenance_message': 'Stiamo lavorando per te !!',
  /////////////////////////////

  // HELMET ///////////////////
  'signup': 'Registrati',
  'home': 'Home',
  'dashboard': 'Dashboard',
  'multimedia': 'Multimedia',
  'multimedia-videos': 'Video multimediali',
  'multimedia-images': 'Immagini multimediali',
  'multimedia-banners': 'Striscioni multimediali',
  'clients': 'Clienti',
  'profile': 'Profilo',
  'company': 'Informazioni aziendali',
  'main-contacts': 'Contatti principali',
  'commercial-info': 'Informazioni commerciali',
  'points-of-sale': 'Punti vendita',
  'catalog': 'Catalogo',
  'catalog_download': 'Scarica il catalogo',
  'notifications': 'Notifiche',
  'helmet_settings': 'Impostazioni',
  'helmet_meta': 'Meta',
  'helmet_users': 'Utenti',
  'helmet_sales-history': 'Storico delle vendite',
  'helmet_upload-sales': 'Carica vendite',
  /////////////////////////////

  // USERS ///////////////////////
  // Departments
  'users_department_commercial': 'Commerciale',
  'users_department_administration': 'Amministrazione',
  'users_department_agent': 'Agente',
  'users_department_owner': 'Proprietario',
  'users_department_store-manager': 'Responsabile del negozio',
  'users_department_logistics': 'Logistica',
  'users_department_marketing': 'Marketing',
  'users_department_others': 'Altri',
  ////////////////////////////////

  // SUPERADMIN //////////////////
  'super-admin_search-input_placeholder': 'Cerca...',
  ////////////////////////////////

  // ACCOUNT //////////////////
  'account_profile_title': 'Il mio profilo',
  'account-info-panel_title': 'Informazioni Generali',
  'account-company-info-panel_title': "Dati dell'azienda",
  // Profile
  'account-info-panel_profile_name': 'Nome',
  'account-info-panel_profile_lastname': 'Cognome',
  'account-info-panel_profile_email': 'Email',
  'account-info-panel_profile_phone-number': 'Numero di telefono',
  'account-info-panel_profile_sector': 'Dipartimento',
  'account-info-panel_profile_security-data': 'Dati di sicurezza',
  'account-info-panel_profile_change-password': 'Cambia password',
  'account-info-panel_profile_pwd_password-label': 'Nuova password',
  'account-info-panel_profile_pwd_repeat-password-label': 'Ripeti la nuova password',
  'account-info-panel_profile_pwd_change-button': 'Cambia password',
  'account-info-panel_profile_pwd_validation': 'La password deve contenere almeno 8 caratteri e un massimo di 16, 1 numero, 1 lettera maiuscola e una minuscola.',
  'account-info-panel_profile_pwd_validation-match': 'Le password non coincidono',
  'account-info-panel_profile_pwd_change-success': 'Password cambiata con successo.',
  'account-info-panel_profile_pwd_empty-inputs': 'Riempire i campi vuoti.',
  // Profile form
  'account-profile_form-input_name': 'Inserisci il tuo nome',
  'account-profile_form-input_name-validation': 'Per favore, inserisci il tuo nome',
  'account-profile_form-input_lastname': 'Inserisci il tuo cognome',
  'account-profile_form-input_lastname-validation': 'Per favore, inserisci il tuo cognome',
  'account-profile_form-input_email': 'Inserisci la tua email',
  'account-profile_form-input_email-validation-required': 'Per favore, inserisci la tua email',
  'account-profile_form-input_email-validation': 'L\'email non è valida',
  'account-profile_form-input_sector': 'Inserisci il dipartimento',
  'account-profile_form-input_sector-validation': 'Per favore, inserisci il dipartimento',
  'account-profile_form-input_phone-number': '',
  // Company
  'account-info-panel_company_title': "Dati dell'azienda",
  'account-info-panel_company_cif': 'Partita CIF',
  // 'account-info-panel_company_cif': 'Partita CIF (Codice di Identificazione Fiscale)',
  'account-info-panel_company_trade-name': 'Nome del Marchio',
  'account-info-panel_company_business-name': 'ragione sociale',
  'account-info-panel_company_address': 'Indirizzo',
  'account-info-panel_company_postal-code': 'Codice postale',
  'account-info-panel_company_population': 'Città',
  'account-info-panel_company_country': 'Paese',
  'account-info-panel_company_province': 'Provincia',
  'account-info-panel_company_contact_name': 'Contatto Principale',
  'account-info-panel_company_email': 'Email',
  'account-info-panel_company_phone-number': 'Numero di telefono',
  'account-info-panel_matas-connected_title': 'Metas Connessi',
  'account-info-panel_active-connections_title': 'Connessioni Attive',
  // Company form
  'account-profile_form-input_phone-number-validation': 'Per favore, inserisci il numero di telefono',
  'account-company_form-input_trade-name': 'Inserisci il nome del Marchio',
  'account-company_form-input_trade-name-validation': 'Per favore, inserisci il nome del Marchio',
  'account-company_form-input_business-name': 'Inserisci ragione sociale',
  'account-company_form-input_business-name-validation': 'Per favore, inserisci la ragione sociale',
  'account-company_form-input_country': 'Inserisci il paese',
  'account-company_form-input_country-validation': 'Per favore, inserisci il paese',
  'account-company_form-input_population': 'Inserisci la Città',
  'account-company_form-input_population-validation': 'Per favore, inserisci la Città',
  'account-company_form-input_address': 'Inserisci l\'indirizzo',
  'account-company_form-input_address-validation': 'Per favore, inserisci il tuo indirizzo',
  'account-company_form-input_postal-code': 'Inserisci il codice postale',
  'account-company_form-input_postal-code-validation': 'Per favore, inserisci il codice postale',
  'account-company_form-input_cif': 'Inserisci il CIF',
  'account-company_form-input_cif-validation': 'Per favore, inserisci il CIF',
  'account-company_form-input_phone-number': 'Numero di telefono',
  'account-company_form-input_phone-number-validation': 'Per favore, inserisci il numero di telefono',
  'account-company_form-input_contact_name': 'Inserisci il contatto principale dell\'azienda',
  'account-company_form-input_contact_name-validation-required': 'Per favore, inserisci il contatto principale dell\'azienda',
  'account-company_form-input_email': 'Inserisci l\'email aziendale',
  'account-company_form-input_email-validation-required': 'Per favore, inserisci la tua email',
  'account-company_form-input_email-validation': 'L\'email non è valida',
  'account-company_form-input_instagram': 'Inserisci l\'URL di Instagram',
  'account-company_form-input_youtube': 'Inserisci l\'URL di Youtube',
  'account-company_form-input_linkedin': 'Inserisci l\'URL di Linkedin',
  'account-company_form-input_facebook': 'Inserisci l\'URL di Facebook',
  'account-company_social-media-title': 'Social Media',
  'account-company_form-input_social-media-label': 'Nome Social Media',
  'account-company_form-input_social-media-label-placeholder': 'Nome del Social Media',
  'account-company_form-input_social-media-label_validation': 'Per favore, inserisci il nome del social media',
  'account-company_form-input_social-media-value': 'URL Social Media',
  'account-company_form-input_social-media-value-placeholder': 'URL del Social Media',
  'account-company_form-input_social-media-value_validation': 'Per favore, inserisci l\'URL del social media',
  'account-company_form-input_social-media-add-button': 'Aggiungi Social Media',
  'account-company_form-input_social-media-accept-button_title': 'Aggiorna Social Media',
  'account-company_form-input_social-media-reject-button_title': 'Scarta modifiche',
  'account-company_form-input_social-media-delete-button_title': 'Elimina Social Media',
  'account-company_form-input_social-media-delete-button_popconfirm_title': 'Elimina Social Media',
  'account-company_form-input_social-media-delete-button_popconfirm_description': 'Sei sicuro di voler eliminare questo Social Media?',
  'account-company_form-input_social-media-delete-button_popconfirm_ok': 'Elimina',
  'account-company_form-input_social-media-delete-button_popconfirm_cancel': 'Annulla',
  // Company drawer
  'account-company_drawer_title': "Dati dell’Azienda",
  // Main Contacts
  'main-contacts_title': 'Contatti Principali',
  'main-contacts_add-contact': '+ Aggiungi',
  'main-contacts_table_name': 'Nome',
  'main-contacts_table_lastname': 'Cognome',
  'main-contacts_table_email': 'Email',
  'main-contacts_table_phone-number': 'Numero di telefono',
  'main-contacts_table_department': 'Dipartimento',
  'main-contacts_table_pop-confirm_title': 'Elimina contatto',
  'main-contacts_table_pop-confirm_description': 'Sei sicuro di voler eliminare questo contatto?',
  'main-contacts_table_pop-confirm_btn-accept': 'Elimina',
  'main-contacts_table_pop-confirm_btn-cancel': 'Annulla',
  // Main Contacts drawer
  'main-contacts_drawer_create-title': 'Crea contatto',
  'main-contacts_drawer_update-title': 'Aggiorna contatto',
  // Main Contacts Form
  'main-contacts_form-label_name': 'Nome',
  'main-contacts_form-label_lastname': 'Cognome',
  'main-contacts_form-label_email': 'Email',
  'main-contacts_form-label_phone-number': 'Numero di telefono',
  'main-contacts_form-label_department': 'Dipartimento',
  'main-contacts_form-input_name': 'Inserisci il nome',
  'main-contacts_form-input_lastname': 'Inserisci il cognome',
  'main-contacts_form-input_email': 'Inserisci l\'email',
  'main-contacts_form-input_department': 'Inserisci il dipartimento',
  'main-contacts_form_sector_label': 'Dipartimento',
  'main-contacts_form-input_sector': 'Inserisci il dipartimento',
  'main-contacts_form-input_sector_validation': 'Inserisci il dipartimento',
  'main-contacts_form-input_phone-number': 'Inserisci il numero di telefono',
  'main-contacts_form-input-validation_name': 'Per favore, inserisci il nome',
  'main-contacts_form-input-validation_lastname': 'Per favore, inserisci il cognome',
  'main-contacts_form-input-validation_email': 'L\'email non è valida',
  'main-contacts_form-input-validation-required_email': 'Per favore, inserisci l\'email',
  'main-contacts_form-input-validation_department': 'Per favore, inserisci il dipartimento',
  'main-contacts_form-input-validation_phone-number': 'Per favore, inserisci il numero di telefono',
  // My brands
  'my-brands_title': 'Le Mie Marche',
  'my-brands_add-to-my-brands': '+ Aggiungi',
  'my-brands_table_brand-name': 'Nome del Marchio',
  'my-brands_table_main-contact': 'Contatto Principale',
  'my-brands_table_phone-number': 'Numero di Telefono',
  'my-brands_table_email': 'Email',
  'my-brands_table_address': 'Indirizzo',
  'my-brands_table_city': 'Città',
  'my-brands_table_pop-confirm_title': 'Scollegare dalle mie marche',
  'my-brands_table_pop-confirm_description1': 'Sei sicuro di voler scollegare',
  'my-brands_table_pop-confirm_description2': 'dalle mie marche?',
  'my-brands_table_pop-confirm_btn-accept': 'Scollegare',
  'my-brands_table_pop-confirm_btn-cancel': 'Annulla',
  'my-brands_table_disassociate-tooltip-title': 'Disassociare il marchio',
  // My brands drawer
  'my-brands_drawer_title': 'Cerca e Aggiungi Marche',
  'my-brands_drawer_add-title': 'Crea un nuovo marchio',
  'my-brands_drawer_go-back-to-brands': 'Torna indietro',
  'my-brands_drawer_form_address': 'Indirizzo',
  'my-brands_drawer_form_address-street-number': 'Via e numero',
  'my-brands_drawer_form_contact': 'Persona di contatto',
  'my-brands_drawer_form_contact_name': 'Nome',
  'my-brands_drawer_form_contact_lastname': 'Cognome',
  'my-brands_drawer_search-placeholder': 'Cerca',
  'my-brands_drawer_more-brands': 'Vuoi un marchio che non vedi qui?',
  'my-brands_drawer_close-drawer': 'Chiudi',
  'my-brands_drawer_brand_associate-btn': 'Associare',
  'my-brands_drawer_brand_see-profile-btn': 'Vedi Profilo',
  'my-brands_disassociate-modal_title': 'Scollegare',
  'my-brands_disassociate-multiple-modal_title': 'Scollega marchi',
  'my-brands_disassociate-multiple-modal_content': 'Sei sicuro di voler scollegare i marchi selezionati e tutti i loro punti vendita?',
  'my-brands_disassociate-multiple-modal_popconfirm-description': 'Sei sicuro di voler scollegare i marchi selezionati?',
  'my-brands_disassociate-modal_on-ok': 'Scollegare',
  'my-brands_disassociate-modal_on-cancel': 'Annulla',
  'my-brands_disassociate-modal_content': 'Sei sicuro di voler scollegare questo marchio e tutti i suoi punti vendita?',
  /////////////////////////////

  // MULTIMEDIA ///////////////
  'multimedia_nav-videos': 'Video',
  'multimedia_nav-images': 'Immagini',
  'multimedia_sort-btn': 'Ordina per',
  'multimedia_filter-btn': 'Filtri',
  'multimedia_apply-filters-btn': 'Applica filtri',
  'multimedia_search-input-placeholder': 'Cerca per marca, genere, stagione, paese, data',
  'multimedia_search-results': 'Risultati',
  'multimedia_sort-last-favorites': 'Preferiti',
  'multimedia_download': 'Scaricare',
  'multimedia_download_started': 'Il download è iniziato',
  'multimedia_open': 'Apri',
  'multimedia_link-copied': 'Link copiato negli appunti',
  'multimedia_link-copy-button': 'Copia link',
  // Sort by
  'multimedia_sort-last-aggregates': 'Ultimi aggiunti',
  'multimedia_sort-best-seller': 'Miglior venditore',
  'multimedia_sort-less-sold': 'Meno venduto',
  'multimedia_sort-best-pvp_great-minor': 'Prezzo: alto-basso',
  'multimedia_sort-best-pvp_minor-great': 'Prezzo: basso-alto',
  'multimedia_sort-best-pvi_great-minor': 'PVI: alto-basso',
  'multimedia_sort-best-pvi_minor-great': 'PVI: basso-alto',
  'multimedia_sort-by-color': 'Per colore da A a Z',
  'multimedia_sort-by-reference': 'Per riferimento da A a Z',
  // Images
  'multimedia_images_popover-edit': 'Modifica',
  'multimedia_images_popover-remove': 'Elimina',
  'multimedia_images_popover-yes': 'Sì',
  'multimedia_images_popover-no': 'No',
  // Filter
  'multimedia_filter-remove-filters': 'Rimuovi filtri',
  'multimedia_filter-season': 'Stagione',
  'multimedia_filter-division': 'Divisione',
  'multimedia_filter-genders': 'Genere',
  'multimedia_filter-visualization': 'Visualizzazione',
  'multimedia_filter-image-rights': 'Diritti d\'immagine',
  'multimedia_filter-country': 'Paese',
  'multimedia_filter-tags': 'Tag',
  'multimedia_filter-favorites': 'I miei preferiti',
  'multimedia_filter-start': 'Trasmissibile dal',
  'multimedia_filter-end': 'Trasmissibile fino',
  // Dragger
  'multimedia_upload-images-manually_dragger_msg-error': 'File consentiti',
  'multimedia_upload-images-manually_dragger_text-1': 'Fai clic o trascina e rilascia un\'immagine',
  // Upload image manually
  'multimedia_upload-images-manually_form-item_selection': 'Seleziona',
  'multimedia_upload-images-manually_form-item_title': 'Titolo',
  'multimedia_upload-images-manually_form-item_title-required': 'Per favore inserisci il titolo dell\'immagine',
  'multimedia_upload-images-manually_form-item_name': 'Nome Tecnico',
  'multimedia_upload-images-manually_form-item_name-required': 'Per favore inserisci il nome dell\'immagine',
  'multimedia_upload-images-manually_form-item_description': 'Descrizione',
  'multimedia_upload-images-manually_form-item_description-required': 'Per favore inserisci la descrizione',
  'multimedia_upload-images-manually_form-item_divisions': 'Divisioni',
  'multimedia_upload-images-manually_form-item_season': 'Stagione',
  'multimedia_upload-images-manually_form-item_language': 'Lingua',
  'multimedia_upload-images-manually_form-item_genders': 'Generi',
  'multimedia_upload-images-manually_form-item_products': 'Prodotti',
  'multimedia_upload-images-manually_form-item_catalog_id': 'ID catalogo',
  'multimedia_upload-images-manually_form-item_catalog_id_text-1': 'Campo associato a Chronos per definire il suo circuito pubblicitario',
  'multimedia_upload-images-manually_form-item_catalog_id_text-2': 'Contatta Hub4Retail per attivare Chronos',
  'multimedia_upload-images-manually_form-item_usage': 'Uso del contenuto',
  'multimedia_upload-images-manually_form-item_visualization': 'Visualizzazione',
  'multimedia_upload-images-manually_form-item_tags': 'Tag',
  'multimedia_upload-images-manually_form-item_tags_text-1': 'Crea le tue etichette',
  'multimedia_upload-images-manually_form-item_start': 'Trasmissibile dal',
  'multimedia_upload-images-manually_form-item_end': 'Trasmissibile fino',
  'multimedia_upload-images-manually_form-item_copyright': 'Questo video è soggetto a diritti d\'immagine?',
  'multimedia_upload-images-manually_form-item_enable_download': 'Consenti il download?',
  'multimedia_upload-images-manually_form-item_countries': 'Paesi',
  'multimedia_upload-images-manually_form-item_add': 'Aggiungi',
  'multimedia_upload-images-manually_form-item_next': 'Successivo',
  'multimedia_upload-images-manually_form-item_yes': 'Sì',
  'multimedia_upload-images-manually_form-item_no': 'No',
  // Modal manually
  'multimedia_upload-images-manually_modal_title': 'Aggiorna immagine',
  'multimedia_upload-images-manually_modal_step-1': 'Seleziona un\'immagine',
  'multimedia_upload-images-manually_modal_step-2': 'Completa i dati',
  'multimedia_upload-images-manually_modal_step-2-msg': 'Titolo dell\'immagine',
  'multimedia_upload-images-manually_modal_step-3-msg': 'Immagine caricata correttamente',
  // Upload images CSV
  'multimedia_upload-images_button': 'Carica immagini',
  'multimedia_upload-images_step-1': 'Seleziona immagini',
  'multimedia_upload-images_step-1_dragger_text-1': 'Fai clic o trascina e rilascia i file immagine',
  'multimedia_upload-images_step-1_dragger_text-2': 'Tieni presente quanto segue...',
  'multimedia_upload-images_step-1_dragger_text-3': 'Salva le immagini con il nome',
  'multimedia_upload-images_step-1_dragger_text-4': 'riferimento_colore_nºimmagine',
  'multimedia_upload-images_step-2': 'Carica CSV',
  'multimedia_upload-images_step-2_dragger_text-1': 'Fai clic o trascina e rilascia il file CSV',
  'multimedia_upload-images_step-3': 'Caricamento completato!',
  'multimedia_upload-images_step-3-text': 'file immagine caricati',
  'multimedia_upload-images_modal_title': 'Carica immagini',
  'multimedia_upload-images_modal_btn-back': 'Indietro',
  'multimedia_upload-images_modal_btn-next': 'Avanti',
  'multimedia_upload-images_modal_btn-close': 'Chiudi',
  ////////////////////////////

  // BRAND USERS /////////////////
  'brand-user_title': 'Utenti',
  'brand-user_description': 'Controlla come i membri del tuo team interagiscono con Hub4Retail.',
  'brand-user_create-user': 'Creare un utente',
  'brand-user_modal_title-create': 'Aggiungi utente',
  'brand-user_modal_title-edit': 'Modifica utente',
  'brand-user_modal_input_name': 'Nome',
  'brand-user_modal_input_lastname': 'Cognome',
  'brand-user_modal_input_email': 'Email',
  'brand-user_modal_input_points-of-sale': 'Punti vendita',
  'brand-user_modal_input_password': 'Password',
  'brand-user_modal_input_btn-save': 'Salva',
  'brand-user_modal_input_btn-cancel': 'Annulla',
  'brand-user_table_name': 'Nome',
  'brand-user_table_email': 'Email',
  'brand-user_table_role': 'Ruolo',
  'brand-user_table_last-session': 'Ultima sessione',
  'brand-user_table_last-session-never': 'Mai',
  'brand-user_delete-popconfirm_title': 'Elimina l’utente',
  'brand-user_delete-popconfirm_description': 'Sei sicuro di voler eliminare questo utente?',
  'brand-user_delete-popconfirm_confirm': 'Sì',
  'brand-user_delete-popconfirm_cancel': 'No',
  ////////////////////////////////

  // CATALOG //////////////////
  'catalog_add-product': 'Aggiungi un prodotto',
  'catalog_ProductList_results': 'Risultati',
  'catalog_no-more-results': 'Nessun risultato trovato...',
  'catalog_tags_delete-all': 'Elimina tutto',
  'catalog_loading-filters': 'Caricamento filtri',
  'catalog_selected-brands-title': 'Marche selezionate',
  'catalog_brand-tag': 'Marca',
  'catalog_brands-tag': 'Marche',
  // Sort by
  'catalog_sort-by_dateAsc': 'Ultimi aggiunti',
  'catalog_sort-by_dateDesc': 'Più vecchi',
  'catalog_sort-by_unitsAsc': 'Più venduti',
  'catalog_sort-by_unitsDesc': 'Meno venduti',
  'catalog_sort-by_nameAsc': 'Nome da A a Z',
  'catalog_sort-by_nameDesc': 'Nome da Z a A',
  'catalog_sort-by_colorAsc': 'Colore da A a Z',
  'catalog_sort-by_colorDesc': 'Colore da Z a A',
  'catalog_sort-by_pviAsc': 'PVI dal minore al maggiore',
  'catalog_sort-by_pviDesc': 'PVI dal maggiore al minore',
  'catalog_sort-by_pvprAsc': 'PVPR dal minore al maggiore',
  'catalog_sort-by_pvprDesc': 'PVPR dal maggiore al minore',
  'catalog_sort-by_referenceAsc': 'Riferimento da A a Z',
  'catalog_sort-by_referenceDesc': 'Riferimento da Z a A',
  'catalog_sort-all-brands': 'Tutti i marchi',
  'catalog_brand-feed-btn': 'Profilo marca',
  // Catalog download
  'catalog_search-input-placeholder': 'Cerca per riferimento, colore o descrizione',
  'catalog_download_alert_brand-required': 'Per favore, seleziona un marchio',
  'catalog_download_alert_get-filters-error': 'Errore durante l\'ottenimento dei filtri',
  'catalog_download_alert_dowloading-compressed-files': 'Scaricamento dei file compressi...',
  'catalog_download_progress-images': 'Immagini',
  'catalog_download_success-msg': 'Immagini scaricate correttamente',
  'catalog_download_success-msg-imgs-and-csv': 'CSV e immagini scaricate correttamente',
  'catalog_download_csv-msg-without-images': 'CSV scaricato correttamente',
  'catalog_download_csv-msg-pending-images': 'CSV scaricato correttamente, ma ci sono immagini in sospeso',
  'catalog_download_csv-msg-pending-images-navigate': 'CSV scaricato correttamente, ma ci sono immagini in sospeso. Puoi continuare a navigare durante il download',
  'catalog_download_apply-changes-btn': 'Applica modifiche',
  'catalog_download_cancel-filter-btn': 'Annulla',
  'catalog_download_cancel-values-selection-btn': 'Cancella selezione',
  'catalog_download_cancel-values-download-btn': 'Annulla download',
  'catalog_download_clear-filters-btn': 'Cancella filtri',
  'catalog_download_result': 'risultato',
  'catalog_download_results': 'risultati',
  'catalog_download_columns_brand': 'Marca',
  'catalog_download_columns_reference': 'Riferimento',
  'catalog_download_columns_ean': 'EAN',
  'catalog_download_columns_size': 'Taglia',
  'catalog_download_columns_name': 'Nome',
  'catalog_download_columns_color': 'Descrizione colore',
  'catalog_download_columns_color_code': 'Codice colore',
  'catalog_download_columns_pvi': 'PVI',
  'catalog_download_columns_pvpr': 'PVPR',
  'catalog_download_columns_division': 'Divisione',
  'catalog_download_columns_gender': 'Genere',
  'catalog_download_columns_season': 'Stagione',
  'catalog_download_columns_segmentation': 'Segmentazione',
  'catalog_download_columns_family': 'Famiglia',
  'catalog_download_columns_material': 'Composizione',
  'catalog_download_columns_images': 'Immagini',
  'catalog_download_columns_all-images': 'Tutti i prodotti',
  'catalog_download_columns_with-images': 'Con immagini',
  'catalog_download_columns_without-images': 'Senza immagini',
  'catalog_download_columns_favorites': 'Preferiti',
  'catalog_download_columns_column-search_search': 'Cerca',
  'catalog_download_columns_column-search_reset': 'Reimposta',
  'catalog_download_columns_column-search_close': 'Chiudi',
  'catalog_download_list_select-all': 'Seleziona tutto',
  'catalog_download_list_clear-all': 'Cancella selezione',
  'catalog_download_list_download-btn': 'Scarica',
  'catalog_download_list_download-images-btn': 'Scarica immagini',
  'catalog_download_list_item': 'articolo',
  'catalog_download_list_items': 'articoli',
  'catalog_download_list_select-order_popover-title': 'Seleziona e ordina',
  ///////////////////////////

  // PRODUCT ///////////////
  'product_infoBox_codeEAN': 'Codici',
  'product_collection_title': 'Tutta la collezione',
  'product_infoProduct_other_colors': 'Altri colori',
  'product_infoProduct_currency': '€',
  'product_infoProduct_sizes': 'Taglie e prezzi',
  'product_infoProduct_EAN_size': 'Taglia',
  'product_infoProduct_EAN_code': 'Codice EAN',
  'product_collapse_title_key1': 'Taglie',
  'product_collapse_title_key2': 'INFORMAZIONI GENERALI',
  'product_collapse_title_key3': 'COMPOSIZIONE',
  'product_card-color': 'Colore',
  'product_mobile_product-details_size': 'Taglia',
  'product_mobile_product-details_info': 'Informazioni Generali',
  'product_mobile_product-details_ean-codes': 'Codici EAN',
  'product_mobile_product-details_ean-code': 'Codice EAN',
  'product_mobile_product-details_composition': 'Composizione',
  'product_related-videos_title': 'Catalogo Multimediale',
  'product_related-videos_subtitle': 'Video Correlati',
  'product_related-products_title': 'Potrebbe anche interessarti...',
  'product_card_sold-units': 'Unità vendute:',
  ///////////////////////////

  // POINTS OF SALE ///////////
  'points-of-sale_title': 'Punti vendita',
  'points-of-sale_add-point-of-sale': '+ Aggiungi',
  'points-of-sale_empty-points-of-sale': 'Non ci sono ancora punti vendita',
  'points-of-sale_table_brand': 'Marca',
  'points-of-sale_table_connection': 'Connessione',
  'points-of-sale_table_recive-data': 'Ricevi dati',
  'points-of-sale_table_share-data': 'Condividi dati',
  'points-of-sale_drawer_title': 'Punti vendita',
  'points-of-sale_drawer_name_label': 'Nome',
  'points-of-sale_drawer_add-edit-sale-point_point-of-sale-type': 'Tipo di Punto Vendita',
  'points-of-sale_drawer_add-edit-sale-point_point-of-sale-type_physical': 'Negozio Fisico',
  'points-of-sale_drawer_add-edit-sale-point_point-of-sale-type_online': 'Negozio Online',
  'points-of-sale_drawer_add-edit-sale-point_category': 'Categoria',
  'points-of-sale_drawer_add-edit-sale-point_category_input-validation': 'Per favore, inserisci la categoria del punto vendita',
  'points-of-sale_drawer_exchange_label': 'Tipo di Valuta',
  'points-of-sale_drawer_add-edit-sale-point_exchange_input-validation': 'Per favore, inserisci il tipo di valuta',
  'points-of-sale_drawer_associate-brand_button_title': 'Aggiungere ai marchi con cui lavora il punto vendita e associarlo ai miei marchi',
  'points-of-sale_drawer_add-brand_button_title': 'Aggiungere ai marchi con cui lavora il punto vendita',
  'points-of-sale_drawer_selected-brand_button_title': 'Associare ai miei marchi',
  'points-of-sale_drawer_associate-all-brands_modal-title-1': 'Richiesta di associazione a',
  'points-of-sale_drawer_associate-all-brands_modal-title-2': 'di',
  'points-of-sale_drawer_associate-all-brands_modal-title-3': 'marchi',
  'points-of-sale_drawer_associate-all-brands_modal-title-4': 'marca',
  'points-of-sale_drawer_associate-all-brands_modal-button-1': 'marchi associati',
  'points-of-sale_drawer_associate-all-brands_modal-button-2': 'Applicando modifiche',
  'points-of-sale_drawer_associate_button_associate-all': 'non associato',
  'points-of-sale_drawer_associate_button_associate-all-1': 'Associare',
  'points-of-sale_drawer_associate_button_associate-all-2': 'Marchi',
  'points-of-sale_drawer_expand-less': 'Vedi meno',
  'points-of-sale_drawer_expand-more': 'Vedi tutti',
  'points-of-sale_drawer_sub-title-1': 'Marchi con cui lavora il punto vendita',
  'points-of-sale_drawer_sub-title-2': 'Aggiungi marchi al punto vendita di',
  'points-of-sale_drawer_associate-all_popconfirm_title-1': 'Vuoi inviare la richiesta di associazione a',
  'points-of-sale_drawer_associate-all_popconfirm_title-2': 'questo marchio',
  'points-of-sale_drawer_associate-all_popconfirm_title-3': 'questi marchi',
  'points-of-sale_drawer_associate-all_popconfirm_description-1': 'in più',
  'points-of-sale_drawer_associate-all_popconfirm_on-ok': 'Associare',
  'points-of-sale_drawer_associate-all_popconfirm_on-cancel': 'Annulla',
  'points-of-sale_drawer_associate-all_modal_title': 'Modifiche effettuate in',
  'points-of-sale_drawer_associate-all_modal_on-ok': 'Salva modifiche',
  'points-of-sale_drawer_associate-all_modal_on-cancel': 'Scarta modifiche',
  'points-of-sale_drawer_associate-all_modal_description-1': 'Ci sono modifiche effettuate nel punto vendita di',
  'points-of-sale_drawer_associate-all_modal_description-2': 'Vuoi salvare le modifiche?',
  'points-of-sale_drawer_name_input': 'Inserisci un nome',
  'points-of-sale_drawer_name_input-validation': 'Per favore, inserisci un nome',
  'points-of-sale_drawer_web_label': 'Web',
  'points-of-sale_drawer_web_input': 'Inserisci un sito web',
  'points-of-sale_drawer_address_label': 'Indirizzo',
  'points-of-sale_drawer_address_input': 'Inserisci un indirizzo',
  'points-of-sale_drawer_address_input-validation': 'Per favore, inserisci un indirizzo',
  'points-of-sale_drawer_postal-code_label': 'Codice postale',
  'points-of-sale_drawer_postal-code_input': 'Inserisci un codice postale',
  'points-of-sale_drawer_postal-code_input-validation': 'Per favore, inserisci un codice postale',
  'points-of-sale_drawer_city_label': 'Città',
  'points-of-sale_drawer_city_input': 'Inserisci una città',
  'points-of-sale_drawer_city_input-validation': 'Per favore, inserisci una città',
  'points-of-sale_drawer_country_label': 'Paese',
  'points-of-sale_drawer_province_label': 'Stato/Provincia',
  'points-of-sale_drawer_province_input': 'Inserisci uno stato/provincia',
  'points-of-sale_drawer_province_input-validation': 'Per favore, inserisci uno stato/provincia',
  'points-of-sale_drawer_region_label': 'Regione',
  'points-of-sale_drawer_region_input': 'Inserisci una regione',
  'points-of-sale_drawer_region_input-validation': 'Per favore, inserisci una regione',
  'points-of-sale_drawer_tel-sufix_label': 'Numero di telefono',
  'points-of-sale_drawer_tel_input': 'Inserisci un numero di telefono',
  'points-of-sale_drawer_tel_input-validation': 'Per favore, inserisci un numero di telefono',
  'points-of-sale_drawer_data-share_label': 'Condividi dati',
  'points-of-sale_drawer_receiving_label': 'Ricevi dati',
  'points-of-sale_drawer_connected_label': 'Connessione',
  'points-of-sale_drawer_brands_title': 'Cerca e aggiungi i tuoi marchi in questo punto vendita',
  'points-of-sale_drawer_brands_input-placeholder': 'Cerca',
  'points-of-sale_drawer_brands_more-brands': 'Vuoi un marchio che non vedi qui?',
  'points-of-sale_drawer_add-point-of-sale_title': 'Aggiungi punto vendita',
  'points-of-sale_drawer_add-brand_title': 'Aggiungi nuovo brand',
  'points-of-sale_drawer_edit-point-of-sale_title': 'Modifica Punto Vendita',
  'points-of-sale_drawer_brands-to-associate-selected': 'Marchi da',
  'points-of-sale_drawer_brands-to-associate-selected-1': '+ Richiesta di associazione',
  'points-of-sale_drawer_brands-selected': 'Marchi in',
  'points-of-sale_drawer__brands--brand__add-brand': 'Aggiungi',
  'points-of-sale_drawer__brands--brand__asociate-brand': 'Associare',
  'points-of-sale_drawer__edit-button': 'Modifica',
  'points-of-sale_add-edit-sale-point_area': 'Superficie commerciale (M2)',
  'points-of-sale_add-edit-sale-point_center-code': 'Codice del centro',
  'points-of-sale_add-edit-sale-point_main-contact': 'Contatto principale',
  /////////////////////////////

  /// THANKS PAGE //////////////
  'ThanksForRegister__register': 'REGISTRAZIONE MARCHI',
  'ThanksForRegister__title': 'Grazie per il tuo interesse in Hub4Retail!',
  'ThanksForRegister__subtitle': 'La tua richiesta è in elaborazione e ti contatteremo al più presto.',
  'ThanksForRegister__button': 'Vai al sito web',
  /////////////////////////////

  /// REGISTER BRAND ///////////
  'RegisterBrand__title1': 'Pronto a iniziare a',
  'RegisterBrand__title2': 'portare il tuo business al',
  'RegisterBrand__title3': 'livello successivo?',
  'RegisterBrand__subtitle': 'Piattaforma progettata per:',
  'RegisterBrand__ex1': 'Migliorare gli strumenti di collaborazione e condivisione.',
  'RegisterBrand__ex2': 'Garantire la sicurezza dei dati in ogni momento.',
  'RegisterBrand__ex3': 'Aumentare i margini di reddito.',
  'RegisterBrand__button': 'Indietro',
  'RegisterBrand__register': 'Registrazione',
  'registerBrand__form__name': 'Nome',
  'registerBrand__form__lastName': 'Cognome',
  'registerBrand__form__business': 'Ragione sociale',
  'registerBrand__form__company': 'Nome commerciale',
  'registerBrand__form__phone': 'Sito web',
  'registerBrand__form__email': 'Telefono',
  'registerBrand__form__web': 'Email aziendale',
  'registerBrand__form__description': 'Vuoi dirci di più?',
  'registerBrand__form__selector': 'Settore',
  'registerBrand__form__say-more': 'Vuoi dirci di più?',
  'registerBrand__form__selector__optionDefault': '',
  'registerBrand__form__selector__option1': 'Calzature',
  'registerBrand__form__selector__option2': 'Accessori',
  'registerBrand__form__selector__option3': 'Casa-Giardino',
  'registerBrand__form__selector__option4': 'Bellezza',
  'registerBrand__form__selector__option5': 'Elettronico',
  'registerBrand__form__selector__option6': 'Sport',
  'registerBrand__form__selector__option7': 'Automobilistico',
  'registerBrand__form__selector__option8': 'Alimentare',
  'registerBrand__form__selector__option9': 'Altro',
  /////////////////////////////

  // BRAND PAGE //////////
  'brand-page_send-association-btn': 'Invia richiesta di associazione',
  'brand-page_history-timeline_title': 'Cronologia',
  'brand-page_history-points-of-sale_title': 'Punti Vendita e Connessione',
  'brand-page_history-points-of-sale_total': 'Totale',
  'brand-page_history-points-of-sale_name': 'Nome',
  'brand-page_history-points-of-sale_address': 'Indirizzo',
  'brand-page_history-points-of-sale_city': 'Città',
  'brand-page_history-points-of-sale_recive-data': 'Ricezione Dati',
  'brand-page_history-points-of-sale_recive-data_active': 'Attivo',
  'brand-page_history-points-of-sale_recive-data_inactive': 'Inattivo',
  'brand-page_history-points-of-sale_status_associate': 'Associato',
  'brand-page_history-points-of-sale_status_pending': 'In sospeso',
  'brand-page_history-points-of-sale_status_no-associate': 'Non associato',
  'brand-page_history-points-of-sale_status': 'Stato',
  'brand-page_history-points-of-sale_status_createdAt': 'Data',
  'brand-page_history-points-of-sale_share-data': 'Condivisione Dati',
  'brand-page_history-points-of-sale_associated': 'Associato',
  'brand-page_history-points-of-sale_not-associated': 'Non associato',
  'brand-page_history-active-contacts_name': 'Nome',
  'brand-page_history-active-contacts_email': 'Email',
  'brand-page_history-active-contacts_phone-number': 'Numero di Telefono',
  'brand-page_history-active-contacts_department': 'Dipartimento',
  'brand-page_history-active-seasons_title': 'Stagioni Attive',
  'brand-page_history-active-contacts_title': 'Contatti Principali',
  'brand-page_history-contacts_popover-title': 'Informazioni di Contatto',
  'brand-page_history-contacts_popover-content_name': 'Nome:',
  'brand-page_history-contacts_popover-content_surname': 'Cognome:',
  'brand-page_history-contacts_popover-content_email': 'Email:',
  'brand-page_history-contacts_popover-content_phone-number': 'Numero di Telefono:',
  'brand-page_history-contacts_popover-content_sector': 'Settore:',
  ////////////////////////

  // SETTINGS PAGE ////////
  'settings-page_nav_chronos': 'Chronos',
  'settings-page_nav_devices': 'Dispositivi',
  'settings-page_nav_touch-screen': 'Schermi Tattili',
  'settings-page_nav_users': 'Utenti',
  'settings-page_nav_system-settings': 'Impostazioni di Sistema',
  'settings-page_nav_meta': 'Meta',
  'settings-page_nav_billing': 'Fatturazione',
  'settings-page_nav_support': 'Supporto',
  // Users page
  'settings-page_users-t-create-user-btn': 'Crea utente',
  /////////////////////////

  // BRAND FEED PAGE ////////
  'brand-feed_page_info_title': 'Riguardo a noi',
  'brand-feed_page_buttons_connect': 'Connetti',
  'brand-feed_page_videos_title': 'Catalogo Multimediale',
  'brand-feed_page_videos_subtitle': 'Video correlati',
  ///////////////////////////

  // NOTIFICATIONS /////////////////
  // Notifications Page
  'notifications-page_notifications-title': 'Notifiche',
  'notifications-page_alerts-title': 'Allerte',
  'notifications_btn-name_catalog': 'Vedi Catalogo',
  'notifications_btn-name_profile': 'Vedi Profilo',
  'notifications_list_show-all': 'Tutto',
  'notifications_list_show-unreaded': 'Non letto',
  'notifications_list_per-page-select': 'per pagina',
  // Popover
  'notifications_popover_title': 'NOTIFICHE',
  'notifications_popover_empty-notifications': 'Nessuna notifica',
  'notifications_popover_btn-name_catalog': 'Vedi Catalogo',
  'notifications_popover_btn-name_profile': 'Vedi Profilo',
  'notifications_popover_item_mark-as-readed_msg': 'Segnato come letto',
  'notifications_popover_item_mark-as-unreaded_msg': 'Segnato come non letto',
  'notifications_popover_item_mark-as-readed': 'Segna come letto',
  'notifications_popover_item_mark-as-unreaded': 'Segna come non letto',
  'notifications_popover_all-notifications-btn': 'Tutte le notifiche',
  'notifications_popover_accept': 'Accetta',
  'notifications_popover_reject': 'Rifiuta',
  'alerts_popover_title': 'ALLERTE',
  'alerts_popover_empty-notifications': 'Nessuna allerta',
  'alerts_popover_all-notifications-btn': 'Tutte le allerte',
  //////////////////////////////////////

  // SALES ///////////////////////////
  'sales_csv-info_title': 'Informazioni CSV',
  'sales_csv-info_columns': 'Colonne:',
  'sales_csv-info_rows': 'Righe:',
  'sales_csv-info_separator': 'Separatore:',
  'sales_csv-info_headers': 'Intestazioni:',
  'sales_csv-info_errors': 'Errori:',
  // Add sales CSV
  'sales_add-sales-csv-input-title': 'Carica CSV',
  'sales_add-sales-csv_warning-msg-file-pnly-contain-a-header': 'Il file contiene solo un\'intestazione',
  'sales_add-sales-csv-error-column-mismatch': 'Alcune righe non corrispondono alle intestazioni del CSV',
  'sales_add-sales-xlsx-input-title': 'Carica XLSX',
  'sales_add-sales-xlsx_select-row_description': 'Seleziona la riga che contiene le intestazioni',
  'sales_add-sales-xlsx_select-row_input-placeholder': 'Nº riga',
  'sales_add-sales-csv-s2-text-1': 'Carica vendite CSV o XLSX',
  'sales_add-sales-csv-s2-text-2': 'Crea o seleziona un mapping di colonne',
  // Sales mapping keys
  'sales_csv-mapping-field__multibrand': 'Multimarca',
  'sales_csv-mapping-field__multibrand_point_of_sale': 'Punto di vendita',
  'sales_csv-mapping-field__point_of_sale_id': 'ID del punto vendita',
  'sales_csv-mapping-field__client_id': 'ID del multimarca',
  'sales_csv-mapping-field__date': 'Data',
  'sales_csv-mapping-field__hour': 'Ora',
  'sales_csv-mapping-field__floor': 'Piano',
  'sales_csv-mapping-field__caja': 'Cassa',
  'sales_csv-mapping-field__order_number': 'Numero d\'ordine',
  'sales_csv-mapping-field__order_line': 'Riga d\'ordine',
  'sales_csv-mapping-field__product_reference': 'Riferimento prodotto',
  'sales_csv-mapping-field__product_description': 'Descrizione prodotto',
  'sales_csv-mapping-field__brand_ean': 'EAN marca',
  'sales_csv-mapping-field__product_ean_code': 'EAN multimarca',
  'sales_csv-mapping-field__product_brand': 'Marca',
  'sales_csv-mapping-field__color_code': 'Codice colore',
  'sales_csv-mapping-field__product_color': 'Nome colore',
  'sales_csv-mapping-field__product_size_eu': 'Taglia',
  'sales_csv-mapping-field__product_season': 'Stagione',
  'sales_csv-mapping-field__division': 'Divisione',
  'sales_csv-mapping-field__family': 'Famiglia',
  'sales_csv-mapping-field__subfamily': 'Sottofamiglia',
  'sales_csv-mapping-field__gender': 'Genere',
  'sales_csv-mapping-field__units': 'Unità',
  'sales_csv-mapping-field__price': 'Prezzo di vendita',
  'sales_csv-mapping-field__cost': 'Prezzo di acquisto',
  'sales_csv-mapping-field__discount': 'Importo sconto',
  'sales_csv-mapping-field__discount_percentaje': 'Sconto%',
  'sales_csv-mapping-field__payment_method': 'Metodo di pagamento',
  'sales_csv-mapping-field__seller': 'Venditore',
  // Handle not matched points of sale
  'sales_csv-upload_not-matched-points-of-sale_title': 'Punti vendita non corrispondenti',
  'sales_csv-upload_not-matched-points-of-sale_description': 'I seguenti punti vendita non corrispondono ai punti vendita dell\'azienda. Si prega di selezionare un punto vendita dall\'elenco per ciascun punto vendita non corrispondente.',
  'sales_csv-upload_not-matched-points-of-sale_checking-matching': 'Verifica dei punti vendita...',
  // My csv mappings
  'sales_csv-mappings_title': 'Mappa corrispondente',
  'sales_csv-mappings_title-create': 'Crea una mappa per il CSV',
  'sales_csv-mappings_save-file_success': 'File di vendite caricato con successo',
  'sales_csv-mappings_send-file-btn': 'Carica vendite',
  // Create mapping form
  'sales_csv-mapping-form_title': 'Seleziona le intestazioni CSV per creare una mappa.',
  'sales_csv-mapping-form_columns-without-reference': 'Colonne CSV non referenziate:',
  'sales_csv-mapping-form_periodicity': 'Periodicità',
  'sales_csv-mapping-form_periodicity_required': 'La periodicità è richiesta',
  'sales_csv-mapping-form_periodicity_dairy': 'Giornaliero',
  'sales_csv-mapping-form_periodicity_weekly': 'Settimanale',
  'sales_csv-mapping-form_periodicity_biweekly': 'Bisettimanale',
  'sales_csv-mapping-form_save-map-btn': 'Salva Mappa',
  'sales_csv-mapping-form_onfinish_field-required-msg': 'I campi contrassegnati con * sono obbligatori',
  'sales_csv-mapping-form_onfinish_map-points-of-sale-required': 'Tutti i punti vendita devono essere mappati',
  // Mapping table
  'sales_csv-mapping-table_column-1_label': 'Campi di vendita',
  'sales_csv-mapping-table_column-2_label': 'Colonne CSV',
  'sales_csv-mapping-table_column-2_select-label': 'Seleziona una colonna',
  'sales_csv-mapping-table_column-2_select-label-multiple': 'Seleziona una o più colonne',
  'sales_csv-mapping-table_column-2_select-format_label': 'Seleziona un formato',
  'sales_csv-mapping-table_column-3_label': 'Dati risultanti',
  // MyCSVMappingInfoPopover
  'sales_csv-mapping-info-popover_periodicity': 'Periodicità:',
  'sales_csv-mapping-info-popover_periodicity-days': 'giorni',
  'sales_csv-mapping-info-popover_date-format': 'Formato data:',
  'sales_csv-mapping-info-popover_points-of-sale-mapped': 'Punti vendita mappati:',
  'sales_csv-mapping-info-popover_references': 'Riferimenti',
  'sales_csv-mapping-info-popover_tags': 'Tag',
  'sales_csv-mapping-info-popover_columns-without-references': 'Colonne non referenziate',
  'sales_csv-mapping-info-popover_popconfirm_title': 'Sei sicuro di voler eliminare questa mappa?',
  'sales_csv-mapping-info-popover_popconfirm_btn': 'Elimina mappa',
  'sales_csv-mapping-info-popover_popconfirm_ok': 'Sì',
  'sales_csv-mapping-info-popover_popconfirm_cancel': 'No',
  // SalesHistoryPage
  'sales-history_page_title': 'Cronologia dei caricamenti delle vendite',
  'sales-history_page_upload-btn': 'Carica vendite',
  'sales-history_page_table_column-1_title': 'Data di caricamento',
  'sales-history_page_table_column-2_title': 'Nome del file',
  'sales-history_page_table_column-3_title': 'Stato',
  'sales-history_page_table_column-3_processed': 'Processato',
  'sales-history_page_table_column-3_pending': 'In sospeso',
  'sales-history_page_table_column-4_popover_title': 'Elimina caricamento delle vendite',
  'sales-history_page_table_column-4_popover_description': 'Sei sicuro di voler eliminare il caricamento delle vendite?',
  'sales-history_page_table_column-4_delete-btn': 'Elimina',
  'sales-history_page_table_column-4_dowload-processed-success': 'File elaborato scaricato correttamente',
  'sales-history_page_table_column-4_dowload-error': 'Errore nel download del file',
  'sales-history_page_table_column-4_dowload-original-success': 'File originale scaricato con successo',
  'sales-history_page_table_column-4_dowload-processed-file': 'Scarica il file elaborato',
  'sales-history_page_table_column-4_dowload-original-file': 'Scarica il file originale',
  'sales-history_page_table_original-name': 'Nome file originale',
  'sales-history_page_table_upload-type': 'Tipo di caricamento',
  'sales-history_page_table_file-type': 'Tipo di file',
  'sales-history_page_table_pending-mapping-title': 'Mappatura in sospeso',
  'sales-history_page_table_mapping-error-title': 'Si è verificato un errore',
  'sales-history_page_table_download-file-error-msg': 'Errore durante il download del file',
  'sales-history_page_table_download-file-format-not-supported': 'Formato file non supportato',
  'sales-history_page_table_create-mapping-label': 'Crea mappatura',
  'sales-history_page_table_info-popover_title': 'Linee del file in cui si verificano errori',
  'sales-history_page_table_info-popover_contact-with-us': 'Se il problema persiste, contatta il team di supporto (help@hub4retail.com) e fornisci questo numero di ID del problema:',
  'sales-history_page_table_info-popover_UNKNOWN_CHAR': 'È stato trovato un carattere che non corrisponde alla codifica specificata nel file.',
  'sales-history_page_table_info-popover_PTO_MISSING': 'Mappatura del punto vendita in sospeso.',
  'sales-history_page_table_info-popover_MISSING_PTO_MAP': 'Mappatura del punto vendita in sospeso.',
  'sales-history_page_table_info-popover_SKIP': 'Elaborazione non riuscita, ignorando.',
  'sales-history_page_table_info-popover_MAP_ID_NOT_FOUND': 'Nessuna mappa corrisponde, crea la mappa per elaborare il file.',
  'sales-history_page_table_info-popover_MAPS_NOT_FOUND': 'Nessuna mappa corrisponde, crea la mappa per elaborare il file.',
  'sales-history_page_table_info-popover_MAP_NOT_FOUND': 'Nessuna mappa corrisponde, crea la mappa per elaborare il file.',
  'sales-history_page_table_info-popover_BRAND_CLIENT_ID_NOT_FOUND': 'Esiste un file creato in una cartella con un ID di marchio o cliente che non esiste più nel database.',
  'sales-history_page_table_info-popover_FOLDER_NOT_FOUND': 'Il record dell\'utente SFTP contiene un ID di marchio o cliente, ma la cartella con questo ID non esiste in Amazon S3, quindi non è possibile recuperare i file.',
  'sales-history_page_table_info-popover_FOLDER_LIST_UNAVAILABLE': 'Impossibile connettersi ad Amazon S3. L\'utente AWS è stato eliminato o i permessi sono cambiati, rendendo impossibile la connessione a S3.',
  'sales-history_page_table_info-popover_FILES_NOT_FOUND': 'La cartella per quel marchio o cliente è vuota. Nessun file da elaborare.',
  'sales-history_page_table_info-popover_NO_CONTENT_TYPE': 'Il file caricato su AWS non ha un tipo di contenuto definito.',
  'sales-history_page_table_info-popover_NO_ENCODING': 'Il file caricato su AWS non ha un tipo di contenuto definito.',
  'sales-history_page_table_info-popover_NO_FILE_METADATA': 'Impossibile leggere il contenuto del file.',
  'sales-history_page_table_info-popover_CANNOT_CREATE_BUFFER': 'Impossibile convertire il flusso di dati del file in un buffer.',
  'sales-history_page_table_info-popover_HEADERS_NOT_FOUND': 'Intestazioni del file non trovate.',
  'sales-history_page_table_info-popover_SIGNATURE_MISMATCH': 'Nessuna mappa corrisponde, crea la mappa per elaborare il file.',
  'sales-history_page_table_info-popover_SEPARATOR_NOT_FOUND': 'Nessun separatore trovato nella mappa.',
  'sales-history_page_table_info-popover_SEPARATOR_MISMATCH': 'Il separatore nella mappa non corrisponde al separatore trovato nell\'intestazione del file.',
  'sales-history_page_table_info-popover_DATAEFORMAT_NOT_FOUND': 'Formato data non trovato.',
  'sales-history_page_table_info-popover_DATE_PARSE_ERROR': 'Il formato della data nella mappa non corrisponde al formato della data nel file.',
  'sales-history_page_table_info-popover_JSON_NORM_ERROR': 'Lettura dei dati del file (json) annullata a causa di errori.',
  'sales-history_page_table_info-popover_JSON_PARSE_ABORT': 'Processo annullato a causa di errori.',
  'sales-history_page_table_info-popover_BRAND_CLIENT_NOT_FOUND': 'ID di marchio o cliente non trovati.',
  'sales-history_page_table_info-popover_MISSING_CSV_DATA': 'Il CSV non contiene dati. Elimina il file se necessario.',
  'sales-history_page_table_info-popover_DATA_TRIM_ERROR': 'Impossibile normalizzare gli spazi in alcune stringhe.',
  'sales-history_page_table_info-popover_DATA_INTEGRITY_ERROR': 'Il numero di colonne in alcune righe non corrisponde al numero di intestazioni. Correggi il file e caricalo di nuovo.',
  'sales-history_page_table_search_reset': 'Ripristina',
  'sales-history_page_delete-sales-history-not-processed-popover_title': 'Elimina tutte le vendite non elaborate',
  'sales-history_page_delete-sales-history-not-processed-popover_description': 'Sei sicuro di voler eliminare tutte le vendite non elaborate?',
  'sales-history_page_delete-sales-history-not-processed-popover_delete-all-btn': 'Elimina tutto',
  'sales-history_page_delete-sales-history-not-processed-msg-success': 'Tutti i file non elaborati sono stati eliminati',
  'sales-history_page_reload-sales-history': 'Ricarica cronologia',
  /////////////////////////////////////

  // META ///////////////////////////
  'meta_connections_title': 'Connessioni',
  'meta_connections_new-connection': '+ Nuova',
  'meta_connections_modal_title': 'Nuova connessione',
  'meta_connections_modal_form_user-name': 'Nome utente',
  'meta_connections_modal_form_user-name_required': 'Per favore inserisci il nome utente',
  'meta_connections_modal_form_password': 'Password',
  'meta_connections_modal_form_password_required': 'Per favore inserisci la password',
  'meta_connections_modal_form_period': 'Frequenza',
  'meta_table_createdAt': 'Data di creazione',
  'meta_table_user-name': 'Utente',
  'meta_table_password': 'Password',
  'meta_table_type': 'Tipo',
  'meta_table_period': 'Frequenza di invio (giorni)',
  ///////////////////////////////////

  // ACTIONS/REDUCER ///////////////
  // Contatti principali
  'action_get-main-contacts_error': 'Errore durante il recupero dei contatti principali',
  'action_select-main-contact_error': 'Errore durante la selezione dei contatti principali',
  'action_create-main-contact_success': 'Contatto creato con successo',
  'action_create-main-contact_error': 'Errore durante la creazione del contatto',
  'action_update-main-contact_success': 'Contatto aggiornato con successo',
  'action_update-main-contact_error': "Errore durante l'aggiornamento del contatto",
  'action_delete-main-contact_success': 'Contatto eliminato con successo',
  'action_delete-main-contact_error': "Errore durante l'eliminazione del contatto",
  // My Brands
  'action_my-brands_get-my-brands_error': 'Errore nel recupero dei miei marchi',
  'action_my-brands_remove-from-my-brands_success': 'Marchio scollegato con successo',
  'action_my-brands_remove-from-my-brands-multiple_success': 'Marchi scollegati con successo',
  'action_my-brands_remove-from-my-brands_error': 'Errore durante lo scollegamento del marchio',
  'action_my-brands_add-to-my-brands_exists': 'Hai già aggiunto questo marchio',
  'action_my-brands_add-to-my-brands_error': 'Errore durante l\'aggiunta del marchio',
  'action_my-brands_add-to-my-brands_success': 'Richiesta di associazione inviata con successo',
  'action_my-brands_create-new-brand_success': 'Marca aggiunta con successo',
  'action_my-brands_create-new-brand_exists': 'Esiste già un marchio con lo stesso nome',
  'action_my-brands_add-to-my-brands_brand-without-tenant': 'Il marchio non è ancora sulla piattaforma, ti contatteremo quando sarà disponibile',
  // Brand
  'action_brand_get-brand_error': 'Errore nell\'ottenere il marchio',
  'action_brand_get-brand_contact-errors': 'Errore nell\'ottenere i contatti del marchio',
  'action_brand_share-data-with-brand_error': 'Errore nella condivisione dei dati con il marchio',
  'action_brand_share-data-with-brand_share-success': 'Condivisione dei dati con il punto vendita',
  'action_brand_share-data-with-brand_no-share-success': 'Hai smesso di condividere i dati con il punto vendita',
  // Sales CSV upload
  'sales-csv-upload_reject-upload-success': 'Caricamento delle vendite rimosso con successo',
  'sales-csv-upload_reject-upload-error': 'Errore nella rimozione del caricamento delle vendite',
  'sales-csv-upload_get-mappings-error-1': 'Errore nel recupero delle chiavi di mapping',
  'sales-csv-upload_get-mappings-error-2': 'Errore del server nel recupero delle chiavi di mapping',
  'sales-csv-upload_get-mappings-error-3': 'Errore nel recupero dei miei mapping',
  'sales-csv-upload_delete-mapping-success': 'Mapping rimosso con successo',
  'sales-csv-upload_delete-mapping-error': 'Errore nella rimozione del mapping',
  'sales-csv-upload_create-mapping-success': 'Mapping creato con successo',
  'sales-csv-upload_create-mapping-success_without-send-file': 'Mappa creata correttamente. Di solito appare nella lista in meno di due minuti.',
  'sales-csv-upload_create-mapping-error': 'Errore nella creazione del mapping',
  // Meta
  'action_meta_get-connections_error': 'Errore nel recupero delle connessioni',
  'action_meta_delete-connection_success': 'Connessione eliminata con successo',
  'action_meta_delete-connection_error': 'Errore nell’eliminazione della connessione',
  'action_meta_create-connection_success': 'Connessione creata con successo',
  'action_meta_create-connection_error': 'Errore nella creazione della connessione',
  //////////////////////////

  // MESSAGE PAGES //////////
  // Error
  'errors-page_404_subtitle': 'Spiacenti, la pagina che stai cercando non esiste.',
  'errors-page_404_btn-go-back': 'Torna indietro',
  // Coming soon
  'coming-soon-page_title': 'Prossimamente!',
  'coming-soon-page_go-back': 'Torna indietro',
  /////////////////////////

  // BREADCRUMBS ///////////////
  // Multimedia
  'breadcrumbs_multimedia': 'Multimedia',
  'breadcrumbs_multimedia-videos': 'Video',
  'breadcrumbs_multimedia-images': 'Immagini',
  // Sales
  'breadcrumbs_sales': 'Vendite',
  'breadcrumbs_sales-history': 'Cronologia dei caricamenti',
  'breadcrumbs_sales-create': 'Carica vendite',
  //Catalog
  'breadcrumb_catalog': 'Catalogo',
  'breadcrumb_product': 'Tutti i marchi',
  'breadcrumb_catalog-download': 'Scarica il catalogo',
  // Clients
  'breadcrumbs_clients': 'Clienti',
  'breadcrumbs_clients-list': 'Elenco clienti',
  'breadcrumbs_add-client': 'Aggiungi cliente',
  'breadcrumbs_resume': 'Riepilogo',
  'breadcrumbs_business-info': 'Informazioni sull\'azienda',
  'breadcrumbs_main-contacts': 'Contatti principali',
  'breadcrumbs_sale-points': 'Punti vendita',
  'breadcrumbs_commercial-info': 'Informazioni commerciali',
  // Account
  'breadcrumbs_account': 'Account',
  'breadcrumbs_account_profile': 'Profilo',
  'breadcrumbs_account_company': "Dati dell'azienda",
  'breadcrumbs_account_contacts': 'Contatti Principali',
  'breadcrumbs_account_my-brands': 'Le Mie Marche',
  'breadcrumbs_account_points-of-sale': 'Punti Vendita',
  //////////////////////////////

  // MODALS ////////////////////
  'modal_email-verified_title': 'Controlla la tua email',
  'modal_email-verified_content': 'Per procedere con il processo, controlla la tua email.',
  'modal_email-verified_content-1': 'Verifica l\'indirizzo dal link ricevuto via email e poi clicca su',
  'modal_email-verified_content_btn_resend': 'Rispedisci verifica',
  'modal_email-verified_content_btn_reload': 'Ricarica',
  'modal_email-verified_content_notif_resend_title': 'Notifica di rispedizione',
  'modal_email-verified_content_notif_resend_content-1': 'La email di verifica è stata inviata a questo indirizzo',
  'modal_email-verified_content_notif_resend_content-2': 'Controlla la tua email per continuare.',
  //////////////////////////////

  // POLICY PRIVACY //////////
  'policy-privacy-modal_title': 'Termini di servizio',
  'policy-privacy-modal_btn-accept': 'Accetta',
  'policy-privacy-modal_btn-cancel': 'Annulla',
  'policy-privacy-modal_checkbox': 'Accetto i Termini di servizio',
  'policy-privacy-modal_checkbox-message': 'Accetta l\'informativa sulla privacy e le condizioni.',
  ////////////////////////////

  // SERVER DOWN MODAL /////
  'server-down-modal_title': 'Errore del server',
  'server-down-modal_content': 'Stiamo riscontrando problemi con i nostri servizi. Riprova più tardi o contatta l\'assistenza tecnica.',
  //////////////////////////

  // NOTIFICATIONS /////////
  'notification-error_message': 'Codice di errore',
  'notification-error_description': 'Riprova più tardi. Se il problema persiste, contatta Hub4Retail (help@hub4retail.com) e fornisci il codice di errore.',
  //////////////////////////

  // GOOGLE ADDRESS FORM //////
  'google-address-form_address': 'Indirizzo',
  'google-address-form_floor': 'Piano',
  'google-address-form_door': 'Porta',
  'google-address-form_postal-code': 'Codice postale',
  'google-address-form_city': 'Città',
  'google-address-form_province': 'Provincia',
  'google-address-form_country': 'Paese',
  'google-address-form_select-country': 'Seleziona un paese',
  // Countries
  'google-address-form_country_spain': 'Spagna',
  'google-address-form_country_italy': 'Italia',
  'google-address-form_country_france': 'Francia',
  'google-address-form_country_portugal': 'Portogallo',
  'google-address-form_country_united kingdom': 'Regno Unito',
  'google-address-form_country_afghanistan': 'Afghanistan',
  'google-address-form_country_brazil': 'Brasile',
  'google-address-form_country_canada': 'Canada',
  'google-address-form_country_india': 'India',
  'google-address-form_country_japan': 'Giappone',
  'google-address-form_country_kenya': 'Kenya',
  'google-address-form_country_mexico': 'Messico',
  'google-address-form_country_nigeria': 'Nigeria',
  'google-address-form_country_philippines': 'Filippine',
  'google-address-form_country_russia': 'Russia',
  'google-address-form_country_south africa': 'Sudafrica',
  'google-address-form_country_thailand': 'Thailandia',
  'google-address-form_country_turkey': 'Turchia',
  'google-address-form_country_argentina': 'Argentina',
  'google-address-form_country_bangladesh': 'Bangladesh',
  'google-address-form_country_colombia': 'Colombia',
  'google-address-form_country_egypt': 'Egitto',
  'google-address-form_country_indonesia': 'Indonesia',
  'google-address-form_country_iran': 'Iran',
  'google-address-form_country_poland': 'Polonia',
  'google-address-form_country_vietnam': 'Vietnam',
  'google-address-form_country_south korea': 'Corea del Sud',
  /////////////////////////////

  // SHARED //////////////////
  'settings': 'Impostazioni',
  'logout': 'Esci',
  'admin-panel_link': 'Pannello di amministrazione',
  'btn-save': 'Salva',
  'btn-cancel': 'Annulla',
  'see-more-content-button': 'See more',
  'see-less-content-button': 'Vedi meno',
  'see-all-content-button': 'Vedi tutto',
  'search-btn-label': 'Cerca',
  'close-btn-label': 'Chiudi',
  ////////////////////////////
}

export default italianTranslation;