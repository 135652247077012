import { IPointOfSaleState, IPointsOfSaleStateInitialState } from 'interfaces/pointsOfSale.interface';
import * as PointsOfSaleType from './pointsOfSale.types'

export default function pointsOfSaleReducer(state: IPointsOfSaleStateInitialState, action: any) {
	const { type, payload } = action;

	switch (type) {
		case PointsOfSaleType.GET_POINTS_OF_SALE:
			return {
				...state,
				pointsOfSale: payload
			}

			case PointsOfSaleType.IS_LOADING_GET_POINTS_OF_SALE:
			return {
				...state,
				isLoadingGetPointsOfSale: payload
			}

		case PointsOfSaleType.SELECT_POINT_OF_SALE:
			return {
				...state,
				pointsOfSaleSelected: payload
			}

		case PointsOfSaleType.CREATE_POINT_OF_SALE:
			return {
				...state,
				pointsOfSale: payload,
				pointsOfSaleSelected: {} as IPointOfSaleState,
			}

		case PointsOfSaleType.UPDATE_POINT_OF_SALE:
			return {
				...state,
				pointsOfSale: payload
			}

		case PointsOfSaleType.UPDATE_POINT_OF_SALE_CONNECTIONS:
			return {
				...state,
				pointsOfSaleSelected: {
					...state.pointsOfSaleSelected,
					[payload.key]: payload.value
				}
			}

		case PointsOfSaleType.SET_POINTS_OF_SALE_CATEGORIES:
			return {
				...state,
				pointsOfSaleCategories: payload,
			}

		case PointsOfSaleType.SET_EXCHANGES:
			return {
				...state,
				exchanges: payload,
			}

		default:
			return state
	}
}