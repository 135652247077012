import * as MetaTypes from './meta.types'
import { metaError } from 'utils/errorCodes';
import { notificationErr } from 'views/components/UI/notification';
import { messageError, messageSuccess } from 'views/components/UI/message';
import { createSftpUserAPI, deleteSftpUserAPI, getSftpUserAPI } from 'api/meta.api';
import { TcreateSftpUserAPIBodyProps } from 'api/responseInterfaces/meta.response.interface';

export async function getSftpConnectionsAction(dispatch: any, clientId: string, userRole: string, token: string, translate: any) {
	dispatch({ type: MetaTypes.SET_IS_LOADING_SFPT_CONNECTIONS, payload: true })
	try {
		const bodyFetch = {
			brand_id: [],
			client_id: [clientId],
			search: [],
			index: 0,
			limit: 1000
		}
		const getSftpUsersFetch = await getSftpUserAPI({ body: bodyFetch, token })
		if (getSftpUsersFetch.response.status === 200) {
			return dispatch({
				type: MetaTypes.SET_SFTP_CONNECTIONS,
				payload: getSftpUsersFetch.data
			})
		}
	} catch (err) {
		messageError(translate('action_meta_get-connections_error'))
		notificationErr(metaError.get_sftp_connections_001, translate)
	} finally {
		dispatch({ type: MetaTypes.SET_IS_LOADING_SFPT_CONNECTIONS, payload: false })
	}
}

export async function removeSftpConnectionAction(dispatch: any, user_name: string, token: string, translate: any) {
	dispatch({
		type: MetaTypes.SET_IS_LOADING_DELETE_SFPT_CONNECTIONS,
		payload: { loading: true, user_name: user_name }
	})
	try {
		const deleteSftpUserConnectionFetch = await deleteSftpUserAPI({ user_name, token })
		if (deleteSftpUserConnectionFetch.response.status === 200) {
			messageSuccess(translate('action_meta_delete-connection_success'))
			return dispatch({
				type: MetaTypes.DELETE_SFTP_CONNECTION,
				payload: user_name
			})
		} else {
			messageError(translate('action_meta_delete-connection_error'))
			notificationErr(metaError.delete_sftp_connection_002, translate)
		}
	} catch (error) {
		messageError(translate('action_meta_delete-connection_error'))
		notificationErr(metaError.delete_sftp_connection_001, translate)
	} finally {
		dispatch({
			type: MetaTypes.SET_IS_LOADING_DELETE_SFPT_CONNECTIONS,
			payload: { loading: false, user_name: "" }
		})
	}
}

export async function createSftpConnectionAction(dispatch: any, body: TcreateSftpUserAPIBodyProps, token: string, translate: any) {
	dispatch({ type: MetaTypes.SET_IS_LOADING_CREATE_SFPT_CONNECTIONS, payload: true })
	try {
		const createSftpUserConnectionFetch = await createSftpUserAPI({ body, token })
		if (createSftpUserConnectionFetch.response.status === 200) {
			messageSuccess(translate('action_meta_create-connection_success'))
			dispatch({
				type: MetaTypes.ADD_NEW_CONNECTION,
				payload: createSftpUserConnectionFetch.data
			})
			return true
		} else {
			messageError(translate('action_meta_create-connection_error'))
			notificationErr(metaError.create_sftp_connection_002, translate)
			return false
		}
	} catch (error) {
		messageError(translate('action_meta_create-connection_error'))
		notificationErr(metaError.create_sftp_connection_001, translate)
		return false
	} finally {
		dispatch({ type: MetaTypes.SET_IS_LOADING_CREATE_SFPT_CONNECTIONS, payload: false })
	}
}