import * as MetaTypes from './meta.types'
import { IInitialMetaState } from 'interfaces/meta.interface';

export default function metaReducer(state: IInitialMetaState, action: any) {
	const { type, payload } = action;

	switch (type) {
		case MetaTypes.SET_SFTP_CONNECTIONS:
			return {
				...state,
				sftpConnections: payload
			}

		case MetaTypes.SET_IS_LOADING_SFPT_CONNECTIONS:
			return {
				...state,
				isLoadingSftpConnections: payload
			}

		case MetaTypes.DELETE_SFTP_CONNECTION:
			return {
				...state,
				sftpConnections: state.sftpConnections.filter((item) => item.name !== payload)
			}

		case MetaTypes.SET_IS_LOADING_DELETE_SFPT_CONNECTIONS:
			return {
				...state,
				isLoadingRemoveSftpConnection: {
					loading: payload.loading,
					user_name: payload.user_name
				}
			}

		case MetaTypes.ADD_NEW_CONNECTION:
			return {
				...state,
				sftpConnections: [...state.sftpConnections, payload]
			}

		case MetaTypes.SET_IS_LOADING_CREATE_SFPT_CONNECTIONS:
			return {
				...state,
				isLoadingCreateSftpConnection: payload
			}

		default:
			return state
	}
}