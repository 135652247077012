import { TcreateSftpUserAPIProps, TcreateSftpUserAPIResponse, TdeleteSftpUserAPIProps, TdeleteSftpUserAPIResponse, TgetSftpUserAPIProps, TgetSftpUserAPIResponse } from "./responseInterfaces/meta.response.interface"
import { basePath, basePathSftpService } from "./utils/config"

export const createSftpUserAPI = async ({ body, token }: TcreateSftpUserAPIProps): Promise<TcreateSftpUserAPIResponse> => {
	const params = {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(body),
	}
	const response = await fetch(`${basePathSftpService}/cli/create_sftp_user`, params)
	const data = await response.json()
	return { response, data }
}

export const getSftpUserAPI = async ({ body, token }: TgetSftpUserAPIProps): Promise<TgetSftpUserAPIResponse> => {
	const params = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(body),
	}
	const response = await fetch(`${basePath}/sftp/get_users`, params)
	const data = await response.json()
	return { response, data }
}

export const deleteSftpUserAPI = async ({ user_name, token }: TdeleteSftpUserAPIProps): Promise<TdeleteSftpUserAPIResponse> => {
	const params = {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
	}
	const response = await fetch(`${basePathSftpService}/cli/delete_sftp_user?user_name=${user_name}`, params)
	const data = await response.json()
	return { response, data }
}